import Swiper, { Navigation, Pagination, Controller, Keyboard } from "swiper";

Swiper.use([Navigation, Pagination, Controller, Keyboard]);

document.addEventListener("DOMContentLoaded", () => {
  const overlay = document.querySelector("#overlay");
  const closeOverlay = overlay.querySelector("#close");
  const images = document.querySelectorAll("article button[data-overlay-hash]");
  const sliderMain = document.querySelector(".swiper-main");
  const sliderInfo = document.querySelector(".swiper-info");

  console.log({ sliderMain, sliderInfo, overlay, closeOverlay, images });
  let swiperMain, swiperInfo;

  toggleOverlay = () => {
    // overlay.classList.toggle("hidden");
    overlay.classList.toggle("translate-y-0");
    overlay.classList.toggle("-translate-y-full");
    document.body.classList.toggle("overflow-hidden");
  };

  closeOverlay.addEventListener("click", () => {
    toggleOverlay();
  });

  if (sliderMain && sliderInfo) {
    swiperInfo = new Swiper(sliderInfo, {
      speed: 300,
      spaceBetween: 100,
      loop: true,
      // autoHeight: true,
    });
    console.log(swiperInfo);

    swiperMain = new Swiper(sliderMain, {
      speed: 300,
      spaceBetween: 100,
      loop: true,
      // autoHeight: true,
      pagination: {
        el: ".pagination",
        type: "fraction",
      },
      navigation: {
        nextEl: ".button-next",
        prevEl: ".button-prev",
      },
      keyboard: {
        enabled: true,
      },
      on: {
        init: function () {
          console.log("afterInit", this.slides, this);
          const swiper = this;
          [...images].map(function (image) {
            image.addEventListener("click", function () {
              console.log("event", this, swiper.slides, swiper);
              const hash = this.dataset.overlayHash;
              const slideIndex = swiper.slides.findIndex((s) => {
                if (s.dataset.slideHash == hash) {
                  console.log(s.dataset.slideHash);
                  return true;
                }
                return false;
              });
              toggleOverlay();
              swiperMain.slideTo(slideIndex, 0);

              console.log(slideIndex, hash);
            });
          });
        },
      },
    });

    swiperMain.controller.control = swiperInfo;
    swiperInfo.controller.control = swiperMain;

    console.log(swiperMain);
  }
});
